
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import StaticPage from "../components/static-page"

const ImpressumPage = () => {


  const siteData = useStaticQuery(graphql`
    query {
      contentfulStaticPage(title: { eq: "Datenschutz" }) {
        title
        content {
          json
        }
      }
    }
  `)

  return (
    <StaticPage content={siteData.contentfulStaticPage.content.json} title={siteData.contentfulStaticPage.title} />
  )
}

export default ImpressumPage
